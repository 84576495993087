.arcade-iframe {
    height: 74vh;
    height: 74svh;
    width: 45.94vh;
    width: 45.94svh;
    border: 1vh solid var(--dark-grey);
    border: 1svh solid var(--dark-grey);
    border-radius: 2vh;
    border-radius: 2svh;
    background-color: var(--eterland-red);
}

.arcade {
    background-color: var(--light-grey);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.arcade-data {
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.arcade-thumbnail {
    width: 90%;
    margin-top: 5%;
    background-repeat: no-repeat;
    background-position: center;
}

.arcade-description {
    font-size: 1.8vh;
    font-size: 1.8svh;
    color: var(--grey);
    font-family: "Gotham Light", serif;
}

.description-container{
    width: 80.8%;
    height: 9.62vh;
    height: 9.62svh;
    align-content: center;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 2.5%;
}

.arcade-trophy {
    height: 30%;
    width: 30%;
    margin-top: 2.5%;
    background-image: url("../images/gift_closed.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.arcade-trophy-empty {
    height: 22%;
    width: 22%;
    margin-top: 2.5%;
    background-image: url("../images/gift_closed_grayscale.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.item-active-container .arcade-trophy-animate {
    animation-name: trophy-animation;
    animation-duration: 1000ms;
    animation-delay: 0ms;
    animation-timing-function: linear;
}

.item-container .title{
    text-align: center;
    font-family: "Lexend variable", Serif;
    font-size: 2.58vh;
    font-size: 2.58svh;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--grey);
    height: 3vh;
    height: 3svh;
    margin-top: 5%;
}

.item-container.item-inactive-container .title{
    font-size: 2.3vh;
    font-size: 2.3svh;
}

.item-container.item-far-inactive-container .title{
    font-size: 2vh;
    font-size: 2svh;
}

.item-active-container .arcade-button{
    pointer-events: none;
}

.item-button-active .arcade-button{
    pointer-events: all;
}

.arcade-button {
    width: 82%;
}

@keyframes trophy-animation {
    0%{
        transform: scale(1);
    }
    60%{
        transform: scale(1.2);
    }
    100%{
        transform: scale(1);
    }
}
