.toolbar {
    width: 100%;
    height: 5vh;
    height: 5svh;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toolbar-button {
    margin-top: 3.42vh;
    margin-top: 3.42svh;
    height: 2.22vh;
    height: 2.22svh;
    width: 2.22vh;
    width: 2.22svh;
    background-image: url("../images/back_button.svg");
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

.toolbar-button[disabled] {
    display: none;
}

.eterland-logo{
    height: 3.34vh;
    height: 3.34svh;
    width: 12.32vh;
    width: 12.32svh;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../images/logo_eterland_color.svg");
    margin-top: 3.17vh;
    margin-top: 3.17svh;
    margin-right: 2.16vh;
    margin-right: 2.16svh;
    z-index: 3;
}

@media (orientation: landscape) {
    .eterland-logo {
        height: 4.46vh;
        height: 4.46svh;
        width: 16.44vh;
        width: 16.44svh;
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: 3.21vh;
        margin-top: 3.21svh;
        margin-right: 4.19vh;
        margin-right: 4.19svh;
        z-index: 3;
    }
}
