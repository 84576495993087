.dropdown
{
    width: 5.57svh;
    height: 3.68svh;
    margin-top: 3svh;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    margin-left: 2.68svh;
}

.dropdown-button {
    width: 3.68svh;
    height: 3.68svh;
    color: var(--grey);
    border-radius: 4.75svh;
    background-color: var(--light-grey);
    font-family: "Lexend variable", serif;
    font-size: 1.25svh;
    font-weight: 500;
    text-transform: uppercase;
}

.dropdown-arrow{
    height: 0.70svh;
    width: 1.25svh;
    background-image: url("../images/arrow_light.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    margin-left: 0.82svh;
    margin-top: 1.57svh;
    border: 0.24svh;
    border-color: var(--light-grey);
}

.dropdown-arrow-up{
    height: 0.70svh;
    width: 1.25svh;
    background-image: url("../images/arrow_light_up.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    margin-left: 0.82svh;
    margin-top: 1.57svh;
    border: 0.24svh;
    border-color: var(--light-grey);
}

.dropdown-menu{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-around;
    width: 3.68svh;
    height: 9.67svh;
    color: var(--grey);
    border-radius: 4.75svh;
    background-color: var(--light-grey);
    font-family: "Lexend variable", serif;
    font-size: 1.25svh;
    font-weight: 500;
    text-align: center;
    flex-grow: 0;
    list-style: none;
    list-style-type: none;
    padding: 0.55svh;
}

.dropdown-item{
    color: var(--grey);
    list-style-position: inside;
    text-transform: uppercase;
    border: 0;
    border-bottom: 1px var(--separator-grey);
    border-style: solid;
    padding-bottom: 0.6svh;
    padding-top: 0.6svh;
}

.dropdown-item:last-child{
    border: unset;
}
