@font-face {
    font-family: 'Roboto Regular';
    src: local('Roboto Regular'), url("../fonts/Roboto-Regular.ttf") format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Light';
    src: local('Roboto Light'), url("../fonts/Roboto-Light.ttf") format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lexend variable';
    src: local('Lexend variable'), url("../fonts/Lexend.ttf") format('woff');
}

@font-face {
    font-family: 'Gotham Medium';
    src: local('Lexend variable'), url("../fonts/Gotham-Medium.otf") format('woff');
}

@font-face {
    font-family: 'Gotham Bold';
    src: local('Lexend variable'), url("../fonts/Gotham-Bold.otf") format('woff');
}

@font-face {
    font-family: 'Gotham Light';
    src: local('Lexend variable'), url("../fonts/Gotham-Light.otf") format('woff');
}

@font-face {
    font-family: 'Roboto Bold';
    src: local('Roboto Bold'), url("../fonts/Roboto-Bold.ttf") format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Black';
    src: local('Roboto Black'), url("../fonts/Roboto-Black.ttf") format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Mikado Regular';
    src: local('Mikado Regular'), url("../fonts/Mikado-Regular.otf") format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Mikado Medium';
    src: local('Mikado Medium'), url("../fonts/Mikado-Medium.otf") format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Mikado Black';
    src: local('Mikado Black'), url("../fonts/Mikado-Black.OTF") format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Regular';
    src: local('Brandon Regular'), url("../fonts/BrandonText-Regular.otf") format('woff');
    font-weight: 400;
    font-style: normal;
}

.line {
    font-family: "Lexend variable", serif;
    color: var(--grey);
    font-size: 1.94vh;
    font-size: 1.94svh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.sub-line {
    font-family: "Gotham Medium", serif;
    font-size: 1.94vh;
    font-size: 1.94svh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.item-container.item-inactive-container .line{
    font-size: 1.75vh;
    font-size: 1.75svh;
}

.item-container.item-far-inactive-container-right .line{
    font-size: 1.6vh;
    font-size: 1.6svh;
}

.item-container.item-far-inactive-container-left .line{
    font-size: 1.6vh;
    font-size: 1.6svh;
}

.bold {
    font-family: "Mikado Black", serif;
}

.title {
    font-family: "Lexend variable", serif;
    width: 38.48vh;
    width: 38.48svh;
    height: 9.62vh;
    height: 9.62svh;
    font-size: 2.63vh;
    font-size: 2.63svh;
    font-weight: 700;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.94vh;
    margin-top: 0.94svh;
}

.sub-title {
    font-family: "Lexend variable", serif;
    width: 38.48vh;
    width: 38.48svh;
    height: 9.62vh;
    height: 9.62svh;
    font-size: 2.63vh;
    font-size: 2.63svh;
    font-weight: 700;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.94vh;
    margin-top: 0.94svh;
}

.message {
    font-family: "Lexend variable", serif;
    font-size: 2.2vh;
    font-size: 2.2svh;
    color: white;
    text-transform: uppercase;
}

.right-align {
    text-align: center;
}

.giant {
    font-size: 4.5vh;
    font-size: 4.5svh;
}

.brandon {
    font-size: 2.68vh;
    font-size: 2.68svh;
    font-family: "Lexend variable", serif;
    font-weight: 300;
    height: 4.68vh;
    height: 4.68svh;
    width: 40.98vh;
    width: 40.98svh;
    margin-top: 2.68vh;
    margin-top: 2.68svh;
}

.description {
    width: 31.48vh;
    width: 31.48svh;
    height: 4.81vh;
    height: 4.81svh;
    color: #E1E1E1;
    text-align: center;
    font-family: "Lexend variable", 'serif';
    font-size: 1.94vh;
    font-size: 1.94svh;
}
