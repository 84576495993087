.App {
  text-align: center;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.background-eterland {
  background-position: center;
  background-image: url("../images/background.png");
  background-size: cover;
}
