*, *::before, *::after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

html, body {
    height: 100%;
    width: 100%;
}

button {
    outline: none;
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 2vh;
    font-size: 2svh;
    font-family: "Mikado Black", serif;
    color: floralwhite;
}

input {
    height: 3.61vh;
    height: 3.61svh;
    width: 30.46vh;
    width: 30.46svh;
    border: none;
    border-radius: 2vh;
    border-radius: 2svh;
    background-color: #eeeeee;
    color: rgba(38, 42, 52, 0.65);
    font-family: "Lexend variable", Serif;
    font-size: 2vh;
    font-size: 2svh;
    padding: 0 3%;
}

input:focus {
    outline: none
}

#root {
    width: 100%;
    height: 100%;
    background-color: #252525;
    --button_disabled: #C6C6C6;
    --button_normal: #FBE34C;
    --button_hover: #F2DA47;
    --button_active: #FCF1AB;
    --light-grey: #E1E1E1;
    --grey: #373737;
    --dark-grey: #28292B;
    --eterland-red: #EE303B;
    --separator-grey: #C6C6C6;
    --description-color: #373737;
    --farmacity-color: #ED7223;
    --landing-email: #828282;
    --button-farmacity-normal: #FBE34C;
}

.absolute-position {
    position: absolute;
}

.full-size {
    width: 100%;
    height: 100%;
}

.full-size-one-players {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.hidden {
    display: none;
}

.invisible {
    visibility: hidden;
    width: 0;
    height: 0;
}

.left-margin {
    margin-left: 3vh;
    margin-left: 3svh;
}

.wide-button {
    height: 4.25vh;
    height: 4.25svh;
    width: 30.43vh;
    width: 30.43svh;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.87vh;
    font-size: 1.87svh;
    border-radius:0.72vh;
    border-radius:0.72svh;

    text-align: center;
    font-family: "Lexend variable";
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 2.85vh;
    margin-top: 2.85svh;

    box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.25);
    border: solid 0.4vh;
    border: solid 0.4svh;
    border-bottom: none;
    border-color: transparent;
    color: #E1E1E1;
    background: var(--button-farmacity-normal);
    --box-shadow-color: #1a9785;
    cursor: pointer;
}

.wide-button-arcade {
    height: 9%;
    width: 75%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.87vh;
    font-size: 1.87svh;
    border-radius:0.72vh;
    border-radius:0.72svh;

    text-align: center;
    font-family: "Lexend variable";
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 1.1vh;
    margin-top: 1.1svh;

    box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.25);
    border: solid 0.4vh;
    border: solid 0.4svh;
    border-bottom: none;
    border-color: transparent;
    color: #E1E1E1;
    background: linear-gradient(#373737, #373737);
    --box-shadow-color: #1a9785;
    cursor: pointer;
}

.wide-button-arcade:hover{
    background: var(--button_hover);
}

.wide-button-arcade:active{
    background: var(--button_active);
}

.wide-button:hover{
    background-color: var(--button_hover);
}

.wide-button:active{
    background-color: var(--button_active);
}

.wide-button[disabled] {
    height: 4.25vh;
    height: 4.25svh;
    width: 30.43vh;
    width: 30.43svh;
    background-color: var(--button_disabled);
    border-color: transparent;
    box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.25);
    margin-top: 2.85vh;
    margin-top: 2.85svh;
}

.logo {
    height: 13%;
    width: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

#landing-gift {
    height: 34.72vh;
    height: 34.72svh;
    width: 34.72vh;
    width: 34.72svh;
    margin-top: 0vh;
    margin-top: 0svh;
    background-image: url("../images/gift_closed.svg");
}

#client-logo {
    width: 30.52vh;
    width: 30.52svh;
    height: 8.3vh;
    height: 8.3svh;
    z-index: 3;
}

.content {
    width: 100%;
    height: 80%;
    position: relative;
    margin-top: 5.94vh;
    margin-top: 5.94svh;
}

.footer {
    height: 3.62vh;
    height: 3.62svh;
    position: absolute;
    top: 94vh;
    top: 94svh;
    width: 100%;
}

#powered-by-etermax {
    position: relative;
    width: 100%;
    height: 3.14vh;
    height: 3.14svh;
    background-image: url("../images/powered_by_etermax.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 3;
}

.screen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    margin-top: 1vh;
    margin-top: 1svh;
}

.game-screen {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.fade {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    background-color: #262a34CC;
    top: 0;
}

.container {
    height: 90%;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}

.gift-container{
    width: 100%;
    height: 2.59vh;
    height: 2.59svh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1vh;
    gap: 1svh;
    margin-top: 0.5vh;
    margin-top: 0.5svh;
}

.flex-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flex-row-space-around {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (orientation: landscape) {
    .container {
        width: 43.4vh;
        width: 43.4svh;
        height: 90%;
        z-index: 3;
    }
}
