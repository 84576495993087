.previous-icon {
    width: 10vh;
    width: 10svh;
    height: 4vh;
    height: 4svh;
    background-image: url("../images/previous-icon.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 1vh;
    margin-right: 1svh;
}

.next-icon {
    width: 10vh;
    width: 10svh;
    height: 4vh;
    height: 4svh;
    background-image: url("../images/next-icon.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 1vh;
    margin-left: 1svh;
}

.loading-icon {
    width: 10vh;
    width: 10svh;
    height: 10vh;
    height: 10svh;
    top: 40%;
    background-image: url("../images/loading-icon.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.gift-icon {
    width: 7.2vh;
    width: 7.2svh;
    height: 6.3vh;
    height: 6.3svh;
    background-image: url("../images/gift_closed.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.gift-progress-active {
    width: 3.2vh;
    width: 3.2svh;
    height: 3.14vh;
    height: 3.14svh;
    background-image: url("../images/gift_progress_active.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.gift-progress-disabled {
    width: 3.2vh;
    width: 3.2svh;
    height: 3.14vh;
    height: 3.14svh;
    background-image: url("../images/gift_progress_disabled.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.star-icon {
    width: 3.8vh;
    width: 3.8svh;
    height: 3.6vh;
    height: 3.6svh;
    background-image: url("../images/star.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    animation: rotation 8s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
    }
}

.sound-on {
    width: 2.31vh;
    width: 2.31svh;
    height: 2.31vh;
    height: 2.31svh;
    margin-left: 3vh;
    margin-left: 3svh;
    margin-top: 3.42vh;
    margin-top: 3.42svh;
    background-image: url("../images/sound_on.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 3;
    cursor: pointer;
}

.sound-off {
    width: 2.31vh;
    width: 2.31svh;
    margin-left: 3vh;
    margin-left: 3svh;
    height: 2.31vh;
    height: 2.31svh;
    margin-top: 3.42vh;
    margin-top: 3.42svh;
    background-image: url("../images/sound_off.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 3;
    cursor: pointer;
}

.spin {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg);
        -webkit-transform: rotate(-360deg);
    }
}
