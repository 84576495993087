.carousel {
    height: 100%;
    width: 100%;
    display: grid;
    gap: 5vh;
    gap: 5svh;
    grid-auto-flow: column;
    grid-auto-columns: 100%;
    padding: 0 15%;
    overflow-x: hidden;
    scroll-behavior: smooth;
    scroll-padding-inline: 22%;
}

.carousel-scroll-icon {
    transition: transform 100ms linear;
    transform: scale(1);
}

.item-container {
    width: 43.29vh;
    width: 43.29svh;
    transition: width, height .2s ease-out;
}

.item-inactive-container {
    height: 49.12vh;
    height: 49.12svh;
    padding-left: 2.68vh;
    padding-left: 2.68svh;
    padding-right: 2.68vh;
    padding-right: 2.68svh;
}

.item-far-inactive-container-right {
    height: 41.90vh;
    height: 41.90svh;
    padding-right: 10.92vh;
    padding-right: 10.92svh;
}

.item-far-inactive-container-left {
    height: 41.90vh;
    height: 41.90svh;
    padding-left: 10.92vh;
    padding-left: 10.92svh;
}

.item {
    overflow: hidden;
}

.item-active {
    height: 100%;
}

.next,
.previous {
    position: absolute;
    height: 13vh;
    height: 13svh;
    width: 6.5vh;
    width: 6.5svh;
    top: 30%;
    z-index: 2;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.previous {
    left: 0;
    border-radius: 0 10vh 10vh 0;
    border-radius: 0 10svh 10svh 0;
    background-color: #40454eCF;
}

.full-size .swiper .swiper-wrapper{
    align-items: center;
}

.next {
    right: 0;
    border-radius: 10vh 0 0 10vh;
    border-radius: 10svh 0 0 10svh;
    background-color: #40454eCF;
}

:where(.next, .previous) > :where(.carousel-scroll-icon){
    animation-name: arrow-animation;
    animation-duration: 300ms;
    animation-timing-function: ease-in-out;
}

:where(.previous, .next):active > :where(.carousel-scroll-icon) {
    animation: none;
}

@keyframes arrow-animation {
    0% { transform: scale(1); }
    50% { transform : scale(1.2); }
    100% { transform: scale(1); }
}

.swiper {
    width: 100%;
    height: 100%;
}

@media (orientation: landscape) {
    .carousel {
        padding: 0 35%;
        scroll-padding-inline: 35%;
    }

    .next, .previous {
        width: 3.5%;
    }

    .item-container {
    }

    .item-active-container {
        padding: 0;
    }
}
