.endcard-image {
    width: 35.2vh;
    width: 35.2svh;
    height: 25.54vh;
    height: 25.54svh;
    background-image: url("../images/gift_open.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    flex-shrink: 0;
    margin-top: 0.3vh;
    margin-top: 0.3svh;

}

.reward-circle-background{
    width: 37.37vh;
    width: 37.37svh;
    height: 37.37vh;
    height: 37.37svh;
    background-color: #2C2C2C;
    border-radius: 37.37vh;
    border-radius: 37.37svh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 3.98vh;
    margin-top: 3.98svh;
}

.ribbon-title{
    width: 38.94vh;
    width: 38.94svh;
    height: 5.25vh;
    height: 5.25svh;
    font-size: 4.18vh;
    font-size: 4.18svh;
    color: white;
    font-family: 'Gotham Medium', 'serif';
    font-style: normal;
    font-weight: 700;
    text-shadow: -1px 0 #28292b, 0 1px #28292b, 1px 0 #28292b, 0 -1px #28292b, 2.2px 1.8px #28292b;
    margin-top: 9.25vh;
    margin-top: 9.25svh;
}

.container > .wide-button.endcard-button{
    width: 36.05vh;
    width: 36.05svh;
    height: 5.36vh;
    height: 5.36svh;
    font-size: 2.14vh;
    font-size: 2.14svh;
    margin-top: -5vh;
    margin-top: -5svh;
}

.endcard-gift-background{
    visibility: hidden;
}

.endcard-willy-background{
    visibility: hidden;
}

.endcard-button{
    font-size: 2.5vh;
    font-size: 2.5svh;
}

.endcard-button:hover{
    background: linear-gradient(#484747, #484747);
}

.endcard-button:active{
    background: linear-gradient(#373737, #373737);
}

#endcard-subtitle{
    color: var(--light-grey, #E1E1E1);

    text-align: center;
    font-size: 2.3vh;
    font-size: 2.3svh;
    font-style: normal;
    font-weight: 400;
    line-height: 1vh; /* 112% */
    line-height: 1vh; /* 112%s */
    margin-top: 5.7vh;
    margin-top: 5.7svh;
    height: 2.44vh;
    height: 2.44svh;
    width: 27vh;
    width: 27svh;
}

#endcard-subtitle-2{
    font-family: "Gotham Light";
    color: var(--light-grey, #E1E1E1);
    text-align: center;
    font-size: 2vh !important;
    font-size: 2svh !important;
    font-style: normal;
    font-weight: 200;
    line-height: 3vh !important; /* 112% */
    line-height: 3svh !important; /* 112%s */
    margin-top: 0 !important;
    margin-top: 0 !important;
    height: 9.44vh !important;
    height: 9.44svh !important;
    width: 43.5vh !important;
    width: 43.5svh !important;
}

@media (orientation: landscape) {

    .endcard-gift-background{
        position: absolute;
        visibility: visible;
        z-index: 2;
        bottom: 12vh;
        bottom: 12svh;
        margin-left: 60vh;
        margin-left: 60svh;
        width: 39vh;
        width: 39svh;
        height: 55vh;
        height: 55svh;
        background-image: url("../images/endcard-gift.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .endcard-willy-background{
        position: absolute;
        visibility: visible;
        z-index: 2;
        bottom: 12vh;
        bottom: 12svh;
        margin-right: 60vh;
        margin-right: 60svh;
        width: 40vh;
        width: 40svh;
        height: 55.5vh;
        height: 55.5svh;
        background-image: url("../images/endcard-willy.png");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
}
