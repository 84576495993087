.scroll-container {
    position: relative;
    height: 56.15vh;
    height: 56.15svh;
    width: 100%;
    margin-top: 8svh;
}

.reward-container > .wide-button{
    width: auto;
    animation-name: button-animation;
    animation-delay: 300ms;
    animation-duration: 600ms;
    animation-timing-function: ease-out;
}

.reward-container{
    display: flex;
    flex-display: row;
    justify-content: space-evenly;
    align-items: center;
    width: 95%;
    z-index: 3;
}

.gift-animation{
    position: absolute;
    top: 16%;
    bottom: 10%;
    z-index: 2;
    width: 67.77vh;
    width: 67.77svh;
    height: 67.77vh;
    height: 67.77svh;
    background-image: url("../images/reward_animation.gif");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transform-origin: 50% 90%;
    pointer-events: none;
}

#home-screen-reward-container{
    width: 16.38vh;
    width: 16.38svh;
    margin-top: 2.36vh;
    margin-top: 2.36svh;
}

#home-screen-reward-message{
    width: 41.99vh;
    width: 41.99svh;
}


@media (orientation: landscape) {
    .reward-container{
        width: 46vh;
        width: 46svh;
    }
}
